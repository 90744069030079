.about {
  height: 100vh;
  width: 100vw;
  color: #fff;
  background-image: url(../../../../images/hero-bg.jpg);
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  background-attachment: fixed;
}
.about::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    #000,
    #1b1b1b38,
    transparent,
    #1b1b1b38,
    #000
  );
}
.about .container {
  position: relative;
  z-index: 12;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.about .container .content {
  backdrop-filter: blur(20px); /* Apply blur effect */
  width: 60vw;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 20px;
  border-radius: 20px;
  overflow: hidden;
  text-align: center;
}

@media (max-width: 768px) {
  .about .container .content {
    width: 80vw;
  }
}
@media (max-width: 448px) {
  .about .container .content {
    width: 95vw;
  }
}
