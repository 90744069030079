.footer {
  background: radial-gradient(#aaa, #aaa);

  --default-color: #000;
  --default-color-rgb: 255, 255, 255;
  --heading-color: #f9f9f9;
  --heading-color-rgb: 249, 249, 249;
  color: var(--default-color);
  background-color: var(--background-color);
  font-size: 14px;
  padding-bottom: 50px;
  position: relative;
}

.footer .copyright {
  padding-top: 25px;
  padding-bottom: 25px;
  border-top: 1px solid rgba(var(--default-color-rgb), 0.1);
}

.footer .copyright p {
  margin-bottom: 0;
}

.footer .credits {
  margin-top: 6px;
  font-size: 13px;
}
