.contact {
  background: linear-gradient(black, #aaa);
  color: #f3f3f3;
  padding-bottom: 5vh;
  padding-top: 10vh;
}
.contact .info-item {
  padding: 20px 0 30px 0;
  box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.1);
  margin-top: 3vh;
}

.contact .info-item i {
  color: var(--accent-color);
  width: 56px;
  height: 56px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  border-radius: 50%;
  border: 2px dotted rgba(var(--accent-color-rgb), 0.6);
}

.contact .info-item h3 {
  font-size: 18px;
  font-weight: 700;
  margin: 10px 0;
}
.contact button {
  margin-left: 15px;
}
.contact .info-item p {
  padding: 0;
  margin-bottom: 0;
  font-size: 14px;
}

.contact .php-email-form {
  height: 100%;
  padding: 30px;
  box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.1);
}

@media (max-width: 575px) {
  .contact .php-email-form {
    padding: 20px;
  }
}

.contact .php-email-form .error-message {
  display: none;
  background: #df1529;
  color: #ffffff;
  text-align: left;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #ffffff;
  background: #059652;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: var(--background-color);
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid var(--accent-color);
  border-top-color: var(--background-color);
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input[type="text"],
.contact .php-email-form input[type="email"],
.contact .php-email-form textarea {
  font-size: 14px;
  padding: 10px 15px;
  box-shadow: none;
  border-radius: 0;
  color: var(--default-color);
  background-color: rgba(var(--background-color-rgb), 0.5);
  border-color: rgba(var(--default-color-rgb), 0.2);
}

.contact .php-email-form input[type="text"]:focus,
.contact .php-email-form input[type="email"]:focus,
.contact .php-email-form textarea:focus {
  border-color: var(--accent-color);
}

.contact .php-email-form input[type="text"]::placeholder,
.contact .php-email-form input[type="email"]::placeholder,
.contact .php-email-form textarea::placeholder {
  color: rgba(var(--default-color-rgb), 0.3);
}

.contact .php-email-form button[type="submit"] {
  color: #000;
  background: #aaa;
  border: 0;
  padding: 8px 30px 10px 30px;
  transition: 0.4s;
  border-radius: 0;
  box-shadow: 0px 0px 10px gray;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.contact .php-email-form button[type="submit"]:hover {
  background: rgba(var(--accent-color-rgb), 0.8);
  color: #fff;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
