.projects {
  height: auto;
  width: 100vw;
  background: radial-gradient(gray, black, black);
  padding-top: 10vh;
  padding-bottom: 5vh;
  /* background-attachment: fixed; */
}

.projects .project {
  height: 40vh;
  clip-path: polygon(40% 0, 60% 0, 100% 100%, 0% 100%);
  cursor: pointer;
  background: radial-gradient(
    ellipse at top,
    white,
    gray,
    transparent,
    transparent
  );

  transition: 0.4s; /* Adjust the transition duration */
  box-shadow: 20px 10px 30px gray;
  margin-top: 40px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
}

.projects .project .project-1 {
  height: 35vh;
  width: 90%;
  clip-path: polygon(40% 0, 60% 0, 100% 100%, 0% 100%);
  cursor: pointer;
  /* background: linear-gradient(gray, rgb(177, 177, 177), gray); */
  transition: 4s;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* background-attachment: fixed; */
  overflow: hidden;
  display: flex;
  justify-content: center;
  position: relative;
  /* align-items: center; */
}
.projects .project .project-1:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgba(2, 2, 2, 0.328); */
  overflow: hidden;
}

.projects .project h2 {
  position: absolute;
  top: 50%;
  /* z-index: 23; */
  font-weight: 600;
  color: white;
  backdrop-filter: blur(20px);
  background-color: rgba(0, 0, 0, 0.507);
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  /* max-width: 18vw; */
  text-align: center;
  font-size: 22px;
}
.projects .project:hover h2 {
  background-color: black;
}
.projects .project:nth-child(1) .project-1 {
  background-image: url(https://images.pexels.com/photos/957024/forest-trees-perspective-bright-957024.jpeg?auto=compress&cs=tinysrgb&w=800);
}
.projects .project:nth-child(2) .project-1 {
  background-image: url(https://images.pexels.com/photos/965345/pexels-photo-965345.jpeg?auto=compress&cs=tinysrgb&w=800);
}
.projects .project:nth-child(3) .project-1 {
  background-image: url(https://images.pexels.com/photos/336372/pexels-photo-336372.jpeg?auto=compress&cs=tinysrgb&w=800);
}
.projects .project:nth-child(4) .project-1 {
  background-image: url(https://images.pexels.com/photos/39284/macbook-apple-imac-computer-39284.jpeg?auto=compress&cs=tinysrgb&w=800);
}
.projects .project:nth-child(5) .project-1 {
  background-image: url(https://images.pexels.com/photos/10031325/pexels-photo-10031325.jpeg?auto=compress&cs=tinysrgb&w=800);
}
.projects .project:nth-child(6) .project-1 {
  background-image: url(https://images.pexels.com/photos/2220337/pexels-photo-2220337.jpeg?auto=compress&cs=tinysrgb&w=800);
}
.section-title h2 {
  color: #fff;
  font-size: 36px;
  text-align: center;
}

@media (max-width: 992px) {
  .projects .project {
    background: none;
  }
}
