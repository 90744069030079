header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1999;
  transition: 0.8s;
  padding-left: 20px;
  padding-right: 20px;
  height: 8vh;
}

header nav {
  display: flex;
  align-items: center;
  transition: 0.8s;
}

header nav .nav-option a {
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  color: #1b1b1b;
}

@media (max-width: 768px) {
  header nav {
    display: none;
  }
}
