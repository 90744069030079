.hero {
  /* width: 100vw; */
  transition: 0.8s;
  /* background-image: url(https://images.pexels.com/photos/171198/pexels-photo-171198.jpeg?auto=compress&cs=tinysrgb&w=800); */
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  /* position: relative; */
  background-color: #aaa;
  height: 100vh;
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(#aaa, #0b2b58, #000);
  transition: opacity 0.8s ease;
  pointer-events: none; /* To prevent the overlay from blocking mouse events */
}
/* .hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(176, 176, 176, 0.461);
} */
.hero .container {
  height: 100vh;
  width: 100vw;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 12;
}

.hero .hero-title {
  position: fixed;
  color: white;
  text-shadow: 0px 0px 10px black;
  transition: 0.8s;
}
/* .hero .hero-title h1 {
  transition: 0.8s;
  position: relative;
  z-index: 2000;
} */
.hero .intro-info {
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 70vw;
  height: 40vh;
  z-index: 123;
  color: #f3f3f3;
  transition: 0.4s;
}
.hero .intro-info h2 {
  font-size: 59px;
  font-weight: 600;
  margin-bottom: 3vh;
}
.hero .hero-title .intro-info p {
  color: black;
  text-shadow: none;
  transition: 0s;
  /* width: 80vw; */
}

.hero .container .row {
  height: inherit;
  /* background-color: green; */
  width: inherit;
  position: relative;
}
.hero .white-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: #fff;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  transition: 1.8s;
  box-shadow: 10px 10px 10px rgb(129, 129, 129);
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.hero .white-bg h1 {
  font-size: 13vw;
  font-weight: 700;
  z-index: 2000;
  transition: 0.8s;

  border-radius: 20px;
  margin-bottom: 20px;
}
.hero .container .hero-cube {
  position: fixed;
  top: 60%;
  left: 40%;
  /* transform: translateX(-50%), translateY(-40%); */
}
.hero .container .hero-cube img {
  width: 200px;
  animation: rotateAnimation 10s linear infinite;
}

@keyframes rotateAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.hero .container .hero-bottom {
  position: absolute;
  bottom: 0;
  height: 50vh;
  padding: 20px;
}
.hero .container .hero-bottom .hero-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.4s;
}
.hero .container .hero-bottom .hero-img {
  height: 11vw;
}

@media (max-width: 768px) {
  .hero {
    height: auto;
  }
  .hero .container {
    height: auto;
    min-height: 100vh;
  }
  .hero .container .hero-bottom {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .hero .container .hero-bottom .hero-info {
    display: none;
    opacity: 0;
  }

  .hero .container .hero-cube {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /* transform: translateX(-50%), translateY(-50%); */
  }
  .hero .intro-info {
    height: auto;
  }
  .hero .intro-info h2 {
    font-size: 38px;
  }
}
@media (max-width: 444px) {
  .hero .intro-info {
    height: auto;
    width: 95%;
  }
  .hero .intro-info h2 {
    font-size: 38px;
  }
}
